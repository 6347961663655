<!--
 * @Author: Dyf
 * @LastEditors: wang hai yang
 * @Date: 2023-03-13 09:32:04
 * @LastEditTime: 2023-05-17 11:10:24
 * @Descripttion: 【内容】文章管理
-->
<style lang="scss" scoped>
.article {
    @include innerPage(56px 34px 48px 15px);
    @include pageTitle(0);
    @include defalutTable(calc(100% - 38px), 20px);
    @include pageFoot;

    .page-title {
        .search-form {
            width: 558px;
            margin-left: auto;
            margin-right: 20px;

            .el-form,
            .el-form-item {
                flex-grow: 1;
            }
        }
    }

    .img {
        width: 62px;
        height: 52px;
        margin-top: 10px;
    }
}

.dialog-container {
    width: calc(100% + 20px);
    max-height: 380px;
    box-sizing: border-box;
    padding: 10px 20px 0 18px;
    margin: 24px 0 48px;
    overflow: hidden;
    overflow-y: auto;

    .tips {
        color: #b0b0b0;
        white-space: nowrap;
    }

    ::v-deep {
        .el-form.page-form .uploader .img-uploader .iconfont {
            padding-bottom: 0;
        }
    }

    .el-form-item:last-child {
        margin-bottom: 0;
    }

    .article-info {
        box-sizing: border-box;
        padding: 0 12px 20px;
        margin-bottom: 36px;
        border-bottom: 1px solid #e8e8e8;

        p {
            width: 760px;
            line-height: 46px;
            box-sizing: border-box;
            color: #1b1b1b;
            @include flexBox(flex-start, flex-start);

            span {
                display: inline-block;
                line-height: 46px;

                &.label {
                    width: 90px;
                    padding-right: 20px;
                    text-align: right;
                    box-sizing: border-box;
                }
            }
        }
    }
}
</style>

<template>
    <section class="article">
        <div class="page-title">
            <h3>文章管理</h3>
            <div class="search-form">
                <el-form inline :model="searchForm">
                    <el-form-item>
                        <el-input v-model.trim="searchForm.query_word" maxlength="100" placeholder="输入关键字">
                            <span slot="prefix" class="iconfont">&#xe61c;</span>
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-button type="custom_primary" @click="getData('search')" size="medium">查询</el-button>
            </div>
            <el-button class="light" type="custom_primary" size="medium" @click="showDialog">
                新增文章+
            </el-button>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" prop="plinf_title" label="文章标题" min-width="21.84%" />
                    <el-table-column align="center" prop="plinf_title" label="封面图" width="100">
                        <template slot-scope="scope">
                            <el-image class="img" fit="cover" :src="formatfile(scope.row.plinf_image)"
                                :preview-src-list="[formatfile(scope.row.plinf_image)]" />
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="plinf_read_num" label="阅读量" min-width="8.92%" />
                    <el-table-column align="center" prop="plinf_praise_num" label="点赞量 " min-width="8.41%" />
                    <el-table-column align="center" label="创建时间" min-width="16.24%">
                        <template slot-scope="scope">
                            <p>{{ timeFormat(scope.row.create_time * 1000) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="create_name" label="发布人" min-width="13.44%" />
                    <el-table-column align="center" label="操作" min-width="25.35%">
                        <template slot-scope="scope">
                            <template v-if="scope.row.plinf_status == 10">
                                <el-button type="custom_primary" size="mini" plain
                                    @click="$router.push({ name: 'CONTENT_ARTICLE_DETAIL', params: { id: scope.row.plinf_id } })">
                                    查看
                                </el-button>
                                <el-button @click="recallArticle(scope.row.plinf_id)" type="custom_warning" size="mini"
                                    plain>
                                    撤回
                                </el-button>
                            </template>
                            <template v-else>
                                <el-button type="custom_primary" size="mini" plain
                                    @click="articleDetail(scope.row.plinf_id)">
                                    编辑
                                </el-button>
                                <el-button @click="publishArticle(scope.row.plinf_id)" type="custom_success" size="mini"
                                    MP-WEIXIN>
                                    发布
                                </el-button>
                                <el-button type="custom_danger" @click="removeArticle(scope.row.plinf_id)" size="mini"
                                    plain>
                                    删除
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='tablePage.pageindex' :total="tablePage.total" @pageChange="flippingPage" />
            </div>
        </div>
        <!-- 编辑 || 新增 -->
        <el-dialog :title="articleForm.plinf_id && '编辑文章' || '新增文章'" width="790px" :visible.sync="articleDialog"
            @close="hideDialog">
            <div class="dialog-container">
                <div class="article-info" v-if="articleForm.plinf_id">
                    
                    <p>
                        <span class="label">创建时间：</span>
                        <span class="value pf">{{ articleForm.create_time }}</span>
                    </p>
                    <p>
                        <span class="label">阅读量：</span>
                        <span class="value pf">{{ articleForm.plinf_read_num }}</span>
                    </p>
                    <p>
                        <span class="label">点赞量：</span>
                        <span class="value pf">{{ articleForm.plinf_praise_num }}</span>
                    </p>
                </div>
                <el-form class="page-form" label-width="68px" :rules="articleRules" ref="articleForm" :model="articleForm">
                    <el-form-item label="封面图" class="uploader" prop="plinf_image">
                        <el-upload class="img-uploader" :action="$upload.imgAction" :show-file-list="false"
                            :accept="$upload.imgAccept" :before-upload="$beforImgUpload"
                            :on-success="(file) => uploadSuccess(file, 'plinf_image')">
                            <div v-if="articleForm.plinf_image" class="img-box">
                                <el-image fit="scale-down" :src="formatfile(articleForm.plinf_image)" class="img" />
                                <i class="del iconfont" @click.stop="removeImg">&#xe604;</i>
                            </div>
                            <div class="upload-wrapper" v-else>
                                <span class="iconfont">&#xe603;</span>
                            </div>
                        </el-upload>
                        <p class="tips">( 选填：支持png/jpg等常规格式，拖拽图 )</p>
                    </el-form-item>
                    <el-form-item label="文章标题" prop="plinf_title">
                        <el-input v-model.trim="articleForm.plinf_title" maxlength="100" placeholder="请输入内容" />
                    </el-form-item>
                    <el-form-item label="文章摘要" prop="plinf_precis">
                        <el-input v-model.trim="articleForm.plinf_precis" maxlength="200" placeholder="请输入内容" />
                    </el-form-item>
                    <el-form-item label="平台显示" prop="plinf_show_type">
                        <el-select v-model="articleForm.plinf_show_type" multiple placeholder="请选择">
                            <el-option label="学生端" value="1" />
                            <el-option label="家长端" value="2" />
                            <el-option label="教师端" value="4" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="内容" class="editor" prop="plinf_content">
                        <div class="ueditor">
                            <!-- <try-tinymce v-model="articleForm.plinf_content" :width="630"></try-tinymce> -->
                            <try-editor v-model="articleForm.plinf_content" :width="630" />
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="foot-flex">
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" :disabled="btnload[0]" @click="handleConfirm(1)"
                    v-loading="btnload[0]">
                    确 定
                </el-button>
                <el-button style="margin-left:auto" type="custom_success" size="small" :disabled="btnload[1]"
                    v-loading="btnload[1]" @click="handleConfirm(2)">
                    发 布
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import ueditorcom from "@comp/ueditorcom"
import { formatTime, formatFile } from "@utils";
import { articleList, articleAdd, articleEdit, articleRemove, articleInfo, articlePublish, articleRecall } from "@api/content"
import customPagination from "@comp/customPagination"
export default {
    name: 'content_article',
    components: {
        ueditorcom,
        customPagination
    },
    computed: {
        timeFormat() {
            return function (time) {
                return formatTime(time, 'YYYY-MM-DD')
            }
        },
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        }
    },
    data() {
        let validateImg = (rule, value, callback) => {
            if (!this.articleForm.plinf_image) {
                callback(new Error('请输入上传封面图'));
            } else {
                callback();
            }
        };
        return {
            tableData: [],
            tablePage: {
                pageindex: 1,
                total: 0,
            },
            searchForm: {},
            articleDialog: false,
            articleForm: {},
            btnload: [false, false],
            articleRules: {
                plinf_image: [{ required: true, validator: validateImg, trigger: "change" }],
                plinf_title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
                plinf_content: [{ required: true, message: "请输入文章内容", trigger: "blur" }],
                plinf_show_type: [{ type: "array", required: true, message: "请选择展示平台", trigger: "change" }],
                plinf_precis: [{ required: true, message: "请输入文章摘要", trigger: "blur" }],
            },
        };
    },
    created() {
        this.getData();
    },
    methods: {
        /** 初始化列表数据 */
        async getData(val) {
            if (val == "search") this.tablePage.pageindex = 1;
            let params = {
                ...this.searchForm,
                pageindex: this.tablePage.pageindex
            }
            const { data: res } = await articleList(params);
            this.tableData = res.data;
            this.tablePage.total = res.allcount;
        },
        /** 切换页码 */
        flippingPage(val) {
            this.tablePage.pageindex = val;
            this.getData();
        },
        /** 删除图片 */
        removeImg() {
            delete this.articleForm.plinf_image;
            this.$refs.articleForm.validateField('plinf_image');
            this.$forceUpdate();
        },
        /** 文件上传成功 */
        uploadSuccess({ data, msg }, el) {
            if (this.$isEmpty(data)) return this.$message.error(msg);
            this.articleForm[el] = data.address;
            this.$refs.articleForm.validateField('plinf_image');
            this.$forceUpdate();
        },
        /** 确认添加 */
        handleConfirm(type) {
            this.$refs.articleForm.validate(async (valid) => {
                if (valid) {
                    this.btnload[type - 1] = true;
                    this.$forceUpdate();
                    let params = {
                        ...this.articleForm,
                        type,
                        plinf_status: type == 1 ? 20 : 10,
                        plinf_show_type: this.articleForm.plinf_show_type.join(',')
                    };
                    delete params.create_time;
                    delete params.plinf_read_num;
                    delete params.plinf_praise_num;
                    let res
                    if (params.plinf_id) {
                        res = await articleEdit(params)
                    } else {
                        res = await articleAdd(params)
                    }
                    this.btnload[type - 1] = false;
                    this.$forceUpdate();
                    if (res) {
                        this.hideDialog();
                        this.$message.success(type == 2 && '发布成功' || params.plinf_id && "编辑成功" || "添加成功");
                        this.getData();
                    }
                }
            });
        },
        /** 文章详情 */
        async articleDetail(id) {
            const { data } = await articleInfo(id);
            let formKeys = ['create_time', 'plinf_read_num', 'plinf_praise_num', 'plinf_image', 'plinf_title', 'plinf_precis', 'plinf_show_type', 'plinf_content', 'plinf_id'];
            let form = {};
            formKeys.map(key => {
                if (key == 'plinf_show_type') form[key] = data[key] && data[key].split(",") || null;
                else form[key] = data[key];
            })
            this.articleForm = { ...form };
            this.articleDialog = true;
            this.$nextTick(() => {
                this.$refs.articleForm.clearValidate();
            })
        },
        /** 删除文章 */
        removeArticle(id) {
            this.$msgbox({
                title: '删除',
                message: '确认删除当前文章？',
                type: 'warning',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                const res = await articleRemove(id);
                if (res.status == 200) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '文章已删除！'
                    });
                    this.tablePage.total = this.tablePage.total - 1 < 0 && 0 || this.tablePage.total - 1;
                    if (this.tableData.length == 1 && this.tablePage.pageindex != 1) {
                        this.tablePage.pageindex = this.tablePage.pageindex - 1;
                    }
                    this.getData();
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /** 撤回文章 */
        recallArticle(id) {
            this.$msgbox({
                title: '提示',
                message: '确认撤回当前文章？',
                type: 'info',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                const res = await articleRecall(id);
                if (res.status == 200) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '文章已撤回！'
                    });
                    this.getData();
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /** 发布文章 */
        publishArticle(id) {
            this.$msgbox({
                title: '提示',
                message: '确认发布当前文章？',
                type: 'info',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                const res = await articlePublish(id);
                if (res.status == 200) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '文章已发布！'
                    });
                    this.getData();
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /** 展开弹窗 */
        showDialog() {
            this.articleDialog = true;
            this.$nextTick(() => this.$refs.articleForm.clearValidate())
        },
        /** 关闭弹窗 */
        hideDialog() {
            this.articleDialog = false;
            this.$refs.articleForm.resetFields();
            this.articleForm = {};
        },
    },
}
</script>